import styled from 'styled-components';

export const LogoContainer = styled.div`
  svg {
    position: relative;
    height: 31px;
    width: 144px;
  }
`;

export const IconContainer = styled.div`
  position: relative;
`;
