export const palette = {
  darkGreen: '#64ac7b',
  lightGreen: '#75b589',
  lightPurple: '#857595',
  offBlack: '#2c2c2c',
  grey: '#3E3E49',
  greyBlue: '#5B5A72',
  lightGreyBlue: '#72718f',
  darkGreyBlue: '#515065',
  offWhite: '#cfcfcf',
  white: '#ffffff',
};

export const colors = {
  white: '#fff',
  spanish_white: '#DFCCB1',
  red: '#E06868',
  green: '#649970',
  grey: '#efefef',
  cool_grey: '#555555',
  warm_grey: '#BAB09D',
  nomad: '#B8B09E',
  // warm_grey: '#B8B09C',
  collective_red: '#C84E20',
  dusty_gold: '#BD945B',
  offBlack: '#292826',
  trace_black: '#211F1E',
};

export const fonts = {
  heading: 'Archivo Black',
  subheading: 'Domaine Display',
  body: 'Montserrat',
};

export const accent = palette.darkGreen;
