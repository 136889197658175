import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import NavConfig from '~config/site-navigation';
import { Consumer } from 'store/createContext';
import MenuIcon from 'images/svg/hamburger-menu.svg';
import { Container, NavContainer, MenuButton } from './nav.css';

const NavItem = ({ item, children }) => {
  return (
    <>
      {item.external === true ? (
        <a
          href={item.path}
          className={`nav-item ${item.class ? item.class : ''}`}
        >
          {item.title}
        </a>
      ) : (
        <Link
          to={item.path}
          activeClassName="active"
          className={`nav-item ${item.class ? item.class : ''}`}
        >
          {children}
        </Link>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
};

const Nav = ({ location = 'header' }) => {
  const config = location in NavConfig ? NavConfig[location] : 'header';
  const [hidden, setHidden] = useState(true);

  return (
    <Container>
      {location === 'header' && (
        <MenuButton onClick={() => setHidden(!hidden)}>
          <MenuIcon />
        </MenuButton>
      )}
      <NavContainer className={`nav-${location} ${!hidden ? 'active' : ''}`}>
        <ul>
          {config &&
            config.map((item, index) => (
              <li
                key={`${item.title}-${index.toString()}`}
                className={`${
                  item.class && item.class.includes('mobile-only')
                    ? 'mobile-only'
                    : ''
                }`}
              >
                <NavItem item={item}>{item.title}</NavItem>

                {item.children && (
                  <ul>
                    {item.children.map((childItem, childIndex) => (
                      <li key={`${childItem.title}-${childIndex.toString()}`}>
                        <NavItem item={childItem}>{childItem.title}</NavItem>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          <li>
            <Consumer>
              {({ state, dispatch }) => {
                if (location === 'header') {
                  return state.authToken ? (
                    <NavItem
                      item={{
                        title: 'Login',
                        path: '/app/dashboard',
                      }}
                    >
                      Dashboard
                    </NavItem>
                  ) : null;
                } else {
                  return state.authToken ? (
                    <a
                      href="#logout"
                      onClick={e => {
                        e.preventDefault();
                        dispatch({ type: 'releaseAuth' });
                      }}
                      className={'nav-item'}
                    >
                      Logout
                    </a>
                  ) : (
                    <NavItem
                      item={{
                        title: 'Login',
                        path: '/app/login/',
                      }}
                    >
                      Login
                    </NavItem>
                  );
                }
              }}
            </Consumer>
          </li>
        </ul>
      </NavContainer>
    </Container>
  );
};

Nav.propTypes = {
  location: PropTypes.string.isRequired,
};

export default Nav;
