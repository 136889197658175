import React from 'react';
import Logo from '~v2/elements/logo';
import Wrapper from '~v2/elements/container/wrapper';
import Nav from '~v2/elements/nav';

import { Container } from './header.css';

const Header = () => (
  <Container>
    <Wrapper>
      <div className="flex flex-row justify-between h-auto lg:h-32 xl:h-auto p-6 xl:p-0">
        <div className="flex flex-auto flex-grow-0 items-center">
          <Logo />
        </div>
        <div className="flex flex-row flex-grow md:flex-grow-0 ml-auto items-center justify-end lg:justify-between">
          <Nav location="header" />
        </div>
      </div>
    </Wrapper>
  </Container>
);

export default Header;
