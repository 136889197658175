import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Grid from 'hedron';

// Import Social Icons.
import InstagramIcon from 'images/svg/social-icons/instagram.svg';
import FacebookIcon from 'images/svg/social-icons/facebook.svg';

// import TwitterIcon from 'images/svg/social-icons/twitter.svg';

import { Container, SocialLink } from './socialLinks.css';

const SocialLinks = () => (
  <StaticQuery
    query={graphql`
      query SocialLinksQuery {
        site {
          siteMetadata {
            social_urls {
              instagram
              facebook
              twitter
            }
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          social_urls: {
            facebook,
            instagram,
            // twitter
          },
        },
      },
    }) => (
      <Container>
        <Grid.Bounds direction="horizontal" valign="center" halign="center">
          <Grid.Box fluid>
            <SocialLink href={instagram} aria-label="Visit our Instagram">
              <InstagramIcon />
            </SocialLink>
          </Grid.Box>

          <Grid.Box fluid>
            <SocialLink href={facebook} aria-label="Visit our Facebook page">
              <FacebookIcon />
            </SocialLink>
          </Grid.Box>

          {/* <Grid.Box fluid>
            <SocialLink href={twitter}>
              <TwitterIcon />
            </SocialLink>
          </Grid.Box> */}
        </Grid.Bounds>
      </Container>
    )}
  />
);

export default SocialLinks;
