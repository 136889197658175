import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BREAKPOINTS } from 'constants/breakpoints';

// Container Box
const Container = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.LG_DESKTOP}px;
  margin: 0 auto;

  @media (width: 1280px) {
    padding: 0 2rem;
    box-sizing: border-box;
  }
`;

const Wrapper = ({ children, className }) => (
  <Container className={`bounding-box ${className}`}>{children}</Container>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

Wrapper.defaultProps = {
  className: '',
};

export default Wrapper;
