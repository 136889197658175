import { createGlobalStyle } from 'styled-components';
import { colors } from 'constants/theme';
import { BREAKPOINTS } from 'constants/breakpoints';

export default createGlobalStyle`
  @font-face {
    font-family: 'Domaine Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: local('Domaine Display'), local('DomainDisplay-Regular'),
    url('./DomaineDisplay-Regular.otf') format('otf'),
  }
  
  a {
    color: ${colors.dusty_gold};

    &:hover {
      color: ${colors.collective_red}
    }
  }


  .container {
    max-width: ${`${BREAKPOINTS.LG_DESKTOP}px`};

    @media (width: 1280px) {
      padding: 0 2rem;
      box-sizing: border-box;
    }
  }

`;
