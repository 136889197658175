import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'hedron';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from '~v2/header';
import Footer from '~v2/footer';

import { GRID_BREAKPOINTS } from 'constants/breakpoints';

import GlobalStyle from 'global.css.js';

import { PageContainer } from './layout.css';

// eslint-disable-next-line no-unused-vars
const Layout = ({ data, children }) => (
  <>
    <GlobalStyle />
    <Head />
    <Grid.Provider breakpoints={GRID_BREAKPOINTS}>
      <Grid.Bounds direction="vertical" style={{ minHeight: '100vh' }}>
        <Grid.Box fluid>
          <Header />
        </Grid.Box>

        <Grid.Box fluid flex="1 0 auto">
          <PageContainer>{children}</PageContainer>
        </Grid.Box>

        <Grid.Box fluid>
          <Footer />
        </Grid.Box>
      </Grid.Bounds>
    </Grid.Provider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  isSmallHeader: PropTypes.bool,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
