export const BREAKPOINTS = {
  LG_DESKTOP: 1280,
  DESKTOP: 992,
  TABLET: 768,
  PHONE: 500,
};

export const GRID_BREAKPOINTS = {
  LG_DESKTOP: '+1280',
  DESKTOP_UP: '+992',
  DESKTOP_DOWN: '-991',
  DESKTOP: '992-1279',
  TABLET_UP: '+768',
  TABLET_DOWN: '-767',
  TABLET: '768-991',
  LG_PHONE: '500-767',
  PHONE_UP: '+500',
  PHONE: '-500',
};

// export const GRID_BREAKPOINTS = {
//   xs: '-500',
//   sm: '768-500',
//   md: '768-992',
//   lg: '992-1280',
//   xl: '+1280',
// };
