import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav``;

export const SocialLink = styled.a`
  margin: 0 0.5rem;

  svg {
    width: 32px;
  }
`;
