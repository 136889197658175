import React from 'react';
import posed from 'react-pose';

import Nav from '~v2/elements/nav';
import Wrapper from '~v2/elements/container/wrapper';
import SocialLinks from '~v2/elements/socialLinks';
import Logo from '~v2/elements/logo';

import { Container } from './footer.css';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Footer = () => (
  <AnimatedContainer>
    <Container>
      <Wrapper>
        <div className="flex flex-col justify-center items-center p-6 xl:p-0 lg:flex-row">
          <div className="flex flex-row  text-center">
            <Logo />
          </div>

          <div className="flex flex-row flex-auto items-center hidden lg:block">
            <SocialLinks />
          </div>

          <div className="flex flex-row py-4">
            <Nav location="footer" />
          </div>

          <div className="block lg:hidden">
            <SocialLinks />
          </div>
        </div>
      </Wrapper>
    </Container>
  </AnimatedContainer>
);

export default Footer;
