import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { LogoContainer, IconContainer } from './logo.css';
import TraceLogo from 'images/svg/trace-logo/horizontal-dark.svg';
import TraceIcon from 'images/svg/trace-logo/icon.svg';

export const AnimatePathOnClick = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    x: '100%',
    opacity: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Logo = ({ type = 'full' }) => (
  <>
    {'full' === type && (
      <LogoContainer className="logo-container">
        <Link to="/">
          <TraceLogo />
        </Link>
      </LogoContainer>
    )}

    {'icon' === type && (
      <IconContainer>
        <Link to="/">
          <AnimatePathOnClick>
            <TraceIcon />
          </AnimatePathOnClick>
        </Link>
      </IconContainer>
    )}
  </>
);

Logo.propTypes = {
  type: PropTypes.string,
};

export default Logo;
