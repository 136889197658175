import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { BREAKPOINTS } from 'constants/breakpoints';
import { colors } from 'constants/theme';

export const Container = styled.header`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.bgColor || colors.white};
  max-width: ${BREAKPOINTS.LG_DESKTOP}
  z-index: 100;

  ${MEDIA.MIN_DESKTOP`
      position: absolute;
  `};

  a {
    color: ${colors.trace_black};
    transition: color 200ms ease, background 125ms ease;
    text-decoration: none;

    &:hover {
      color: ${colors.dusty_gold};
    }
  }

  nav {
    padding: 2rem 0;
    ${'' /* height: 35px; */}
  }
`;
