import styled from 'styled-components';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  .nav-header {
    display: none;

    &.active {
      display: block;
    }

    ul {
      flex-direction: column;
      align-items: center;
    }
    li {
      text-align: center;
      margin: 0;
      width: 100%;
    }
  }

  ${MEDIA.MIN_DESKTOP`
    .nav-header {
      display: block;
      margin-bottom: 0;
      
      ul {
        flex-direction: row;
        align-items: space-between;
      }
      
      li {
        width: auto;
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    
    .mobile-only {
      display: none;
    }
  `};

  ${MEDIA.PHONE`
    .nav-header {
      display: none;
      
      &.active {
        display: block;
      }
    }
  `};
`;

export const NavContainer = styled.nav`
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    ${MEDIA.TABLET`
      justify-content: center;
    `};

    li {
      text-transform: uppercase;
      font-size: 1.3rem;

      a {
        padding: 0.4rem;
        letter-spacing: 1px;
        font-family: 'Archivo Black';
        color: ${colors.trace_black};
        font-size: 12px;
        line-height: 25px;

        &.nav-item-block {
          background-color: ${colors.trace_black};
          color: ${colors.white};

          &:hover {
            background-color: ${colors.red};
          }
        }
      }
    }
  }
`;

export const MenuButton = styled.button`
  display: block;
  background: none;
  border: none;
  width: 36px;
  margin-left: auto;
  cursor: pointer;

  ${MEDIA.MIN_DESKTOP`
      display: none;
  `};

  svg {
    fill: ${colors.spanish_white};
  }

  &:hover {
    svg {
      fill: ${colors.dusty_gold};
    }
  }

  &:active {
    svg {
      opacity: 0.8;
    }
  }
`;
