module.exports = {
  header: [
    {
      title: 'Manifesto',
      path: '/manifesto/',
    },
    {
      title: 'Exchange',
      path: '/catalog/',
    },
    {
      title: 'Blog',
      path: '/blog/',
    },
    {
      title: 'Contact',
      path: '/contact/',
    },
    // {
    //   title: 'Shop',
    //   path: '/shop/',
    // },
    {
      title: 'Users',
      path: '/users/',
    },
    {
      title: 'FAQ',
      path: '/faq',
      class: 'mobile-only',
    },
    {
      title: 'Our Team',
      path: '/about/team/',
      class: 'mobile-only',
    },
    {
      title: 'Careers',
      path: '/careers/',
      class: 'mobile-only',
    },
    {
      title: 'Privacy',
      path: '/privacypolicy/',
      class: 'mobile-only',
    },
  ],
  footer: [
    {
      title: 'Exchange',
      path: '/catalog/',
    },
    {
      title: 'FAQ',
      path: '/faq',
    },
    {
      title: 'Our Team',
      path: '/about/team/',
    },
    {
      title: 'Careers',
      path: '/careers/',
    },
    {
      title: 'Privacy',
      path: '/privacypolicy/',
    },
    {
      title: 'Contact',
      path: '/contact/',
    },
    {
      title: 'Users',
      path: '/users/',
      // class: 'nav-item-block',
    },
  ],
};
