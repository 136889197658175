import styled from 'styled-components';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.footer`
  padding: 2rem 0 0;
  background-color: ${colors.white};

  ${MEDIA.MIN_DESKTOP`
    padding: 3rem 0;
  `};

  a {
    color: ${colors.trace_black};
    transition: color 200ms ease, background 125ms ease;
    text-decoration: none;

    &:hover {
      color: ${colors.dusty_gold};
      fill: ${colors.dusty_gold};
    }
  }
`;
