import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const PageContainer = styled.div`
  ${MEDIA.PHONE`
    .phone-order-minus-one {
      order: -1;
    }
    .phone-order-plus-one {
      order: +1;
    }
  `};

  ${MEDIA.TABLET`
    .tablet-order-minus-one {
      order: -1;
    }
    .tablet-order-plus-one {
      order: +1;
    }
  `};

  ${MEDIA.DESKTOP`

  `};
`;
